import React from 'react'
import get from 'lodash/get'

import * as messages from '../../messages.json'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import { SEOMetaTags } from 'blog-components'
import InstagramEmbed from 'react-instagram-embed'

class Giveaway extends React.Component {
  successComponent() {
    return (
      <div className="main-container">
        <div className="row justify-content-center no-gutters text-center">
          <div className="col-sm-8">
            <h1 className="display-4 py-5">
              {messages.pages.giveaway.components.success.heading}
            </h1>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const site = get(this, 'props.data.site')

    return (
      <Layout>
        <SEOMetaTags
          path="/giveaway/"
          site={site}
          title={messages.pages.giveaway.metaData.title}
          description={messages.pages.giveaway.metaData.description}
          imageUrl={site.siteMetadata.siteUrl + '/main-logo.png'}
        />
        <div className="row justify-content-center no-gutters px-3">
          <div className="col-sm-8">
            <h1 className="display-4 text-center py-5 ">
              {messages.pages.giveaway.components.heading}
            </h1>
            <p className="lead">
              {messages.pages.giveaway.components.paragraph_1}
            </p>
            <p className="lead">
              {messages.pages.giveaway.components.paragraph_2}
            </p>
            <ul className="pb-3 lead">
              <li>{messages.pages.giveaway.components.listelement_1}</li>
              <li>{messages.pages.giveaway.components.listelement_2}</li>
              <li>{messages.pages.giveaway.components.listelement_3}</li>
            </ul>
            <p className="lead">
              {messages.pages.giveaway.components.paragraph_3}
            </p>
            <div className="row justify-content-center">
              <div className="col-sm-8">
                <InstagramEmbed
                  url="https://www.instagram.com/p/BsawNrsB4wc/"
                  maxWidth={640}
                  hideCaption={true}
                  containerTagName="div"
                  protocol=""
                  injectScript
                  onLoading={() => {}}
                  onSuccess={() => {}}
                  onAfterRender={() => {}}
                  onFailure={() => {}}
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Giveaway
export const query = graphql`
  query giveawayQuery {
    site {
      ...SiteInformation
    }
  }
`
